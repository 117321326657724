import { ProductInfoProduct } from '../../types'

const DEFAULT_QUANTITY = 1
const DEFAULT_VARIANT_KEY = 1

export const initialState = {
  initialProduct: {} as ProductInfoProduct,
  initialVariantKey: DEFAULT_VARIANT_KEY,
  product: {} as ProductInfoProduct,
  upsellProduct: undefined,
  variant: DEFAULT_VARIANT_KEY,
  addon: null,
  quantity: DEFAULT_QUANTITY,
  isGroupCardSelected: false,
}
