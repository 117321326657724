import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import {
  ScreenReaderOnly,
  Text,
  VerticalStack,
} from '@moonpig/launchpad-components'
import { Selector, SelectorContentWithLabel } from '@moonpig/launchpad-forms'
import { getVariantIcon } from '../../utils/getVariantIcon'

const StyledFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border-width: 0px;
  text-align: left;
`

export type VariantSelectorItem = {
  label: string
  description?: string
  value: string
  price: string
  discountedPrice?: string
  absolutePrice?: string
  dimensions?:
    | {
        description: string
      }
    | string
    | null
}

export type VariantSelectorProps = {
  title?: string
  selectedValue: string
  items: VariantSelectorItem[]
  isLandscape: boolean
  onChange: (variantValue: string) => void
}

export const VariantSelector: FC<VariantSelectorProps> = ({
  title,
  selectedValue,
  items,
  isLandscape,
  onChange,
}) => {
  return (
    <StyledFieldset data-testid="variant-selector">
      <Text
        data-testid="variant-select-title"
        as="legend"
        typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' }}
        color={'colorTextHeadline'}
        p="0"
        m="0"
        mb={4}
      >
        {title}
      </Text>
      <VerticalStack spacing={3}>
        {items.map(
          ({
            label,
            description,
            price,
            value,
            discountedPrice,
            absolutePrice,
            dimensions,
          }) => {
            const screenReaderPrice = discountedPrice || absolutePrice || price

            const dimensionsLabel =
              typeof dimensions === 'string'
                ? dimensions
                : dimensions?.description

            const dimensionsScreenReaderLabel = dimensions
              ? `Dimensions: ${dimensionsLabel}`
              : null

            const screenReaderLabel = [
              label,
              screenReaderPrice,
              description,
              dimensionsScreenReaderLabel,
            ]
              .filter(labelPart => !!labelPart)
              .join(' - ')

            return (
              <Selector
                key={label}
                type="radio"
                name="variant-selector"
                id={`variant-selector-${value}`}
                value={value}
                checked={selectedValue === value}
                onChange={() => {
                  onChange(value)
                }}
              >
                <SelectorContentWithLabel
                  aria-hidden="true"
                  headingText={
                    <>
                      <div
                        data-testid={`${label}-selector-heading`}
                        aria-hidden="true"
                      >
                        {label}
                      </div>
                      <ScreenReaderOnly
                        testId={`${label}-selector-screenreader-text`}
                      >
                        {screenReaderLabel}
                      </ScreenReaderOnly>
                    </>
                  }
                  bodyText={
                    <div
                      data-testid={`${label}-description`}
                      aria-hidden="true"
                    >
                      {description || ''}
                    </div>
                  }
                  labelText={
                    discountedPrice ? (
                      <>
                        <Text
                          data-testid={`${label}-discounted-price`}
                          typography="typeBodyLabel"
                          aria-hidden="true"
                        >
                          {discountedPrice}
                        </Text>{' '}
                        <Text
                          data-testid={`${label}-price`}
                          color="colorBlack80"
                          aria-hidden="true"
                        >
                          <s>{price}</s>
                        </Text>
                      </>
                    ) : (
                      <div data-testid={`${label}-price`} aria-hidden="true">
                        {price}
                      </div>
                    )
                  }
                  icon={getVariantIcon({ sizeId: value, isLandscape })}
                />
              </Selector>
            )
          },
        )}
      </VerticalStack>
    </StyledFieldset>
  )
}
